import {
  FC, memo, useCallback, useContext,
} from 'react'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalType, useModal } from '@modals/index'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { WindowSizesContext } from '@share/album'

type Props = {
  album_id: string
}

export const AlbumHeadBottom: FC<Props> = memo((props: Props) => {
  const windowSizes = useContext(WindowSizesContext)
  const isMobile = useIsMobile()
  const { openModal } = useModal()

  const onChangeLayout = useCallback(() => {
    openModal({
      type: ModalType.UPDATE_ALBUM_LAYOUT_MODAL,
      album_id: props.album_id,
    })
  }, [props.album_id])

  const headStyle = { width: `${isMobile ? windowSizes.outer.width : windowSizes.outerHalf.width}px` }

  return (
    <div className="w-full m-auto md:pt-4 mb-2 md:mb-0" style={headStyle}>
      <Button
        variant="flat"
        className="text-black bg-gray-very-light w-full"
        startContent={<FontAwesomeIcon size="sm" icon={faPencil} className="text-gray-300" />}
        size={isMobile ? 'sm' : 'lg'}
        onClick={onChangeLayout}
      >
        Change layout
      </Button>
    </div>
  )
})
