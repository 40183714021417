import { FC, memo, useMemo } from 'react'
import {
  Album, AlbumPage, AlbumPageType, CoverColor, ImageSize,
} from '@api/gql/graphql'
import {
  AlbumBackCover,
  AlbumFrontCover,
  AlbumPicture,
  PageLayout,
} from '@share/album'
import { clsx } from 'clsx'

import { PageItemSort } from './page-item-sort'

type Props = {
  id?: string
  album: Album
  page: AlbumPage
  spreadIndex: number
  selectedImageIds?: string[]
  isLeft?: boolean
  isRight?: boolean
  isWide?: boolean
  isDivider?: boolean
  isControl?: boolean
  isSpread?: boolean
  isSortable?: boolean
  onClick?: () => void
  spreadPages?: AlbumPage[]
  downSpreadPages?: AlbumPage[]
  upSpreadPages?: AlbumPage[]
  pageLayoutClassName?: string
};

export const PageItem: FC<Props> = memo((props: Props) => {
  // Проверяет переданные страницы на сортируем ость
  const canSortable = (albumPages: AlbumPage[]): boolean => {
    if (albumPages.length === 0) {
      return false
    }

    // Сортировать можно только на аналогичный разворот с двумя страницами
    if (albumPages.length !== 2) {
      return false
    }

    const types = albumPages.map((p) => p.type as AlbumPageType) ?? []
    return types.every(
      (pageType) => pageType === AlbumPageType.Picture || pageType === AlbumPageType.Text,
    ) ?? false
  }

  const isSpreadSortable = useMemo(() => {
    return canSortable(props.spreadPages ?? [])
  }, [props.spreadPages])

  const isUpSortable = useMemo(
    () => {
      return canSortable(props.upSpreadPages ?? [])
    },
    [props.upSpreadPages],
  )

  const isDownSortable = useMemo(
    () => {
      return canSortable(props.downSpreadPages ?? [])
    },
    [props.downSpreadPages],
  )

  // После 5-го разворота изображения будет загружено когда пользователь
  // непосредственно посмотрит изображение
  const isLazyLoading = props.spreadIndex > 5

  const pageLayoutClassName = useMemo(() => {
    const isCover = [
      AlbumPageType.FrontCover,
      AlbumPageType.BackCover,
    ].includes(props.page.type as AlbumPageType)

    const color = isCover ? clsx(
      props.album.cover_color === CoverColor.White && 'bg-white',
      props.album.cover_color === CoverColor.Red && 'bg-cover-red border-cover-red',
      props.album.cover_color === CoverColor.Beige && 'bg-cover-beige border-cover-beige',
      props.album.cover_color === CoverColor.Green && 'bg-cover-green border-cover-green',
      props.album.cover_color === CoverColor.Blue && 'bg-cover-blue border-cover-blue',
    ) : ''

    return clsx(
      'relative md:rounded-xl',
      'text-[0.26em] sm:text-[0.53em] md:text-[0.6em] lg:text-[0.8em]',
      props.isLeft && 'rounded-l-md',
      props.isRight && 'rounded-r-md',
      props.isLeft && props.isDivider && 'border-r-3 border-gray-200 md:border-0',
      props.pageLayoutClassName,
      isCover ? 'border-1 md:border-2' : 'bg-gray-80',
      color,
    )
  }, [props])

  return (
    <div
      className="relative mb-2 md:mb-0"
      id={props.id}
      data-id={props.page.id}
    >
      <PageLayout
        isWide={props.isWide}
        className={pageLayoutClassName}
        onClick={props.onClick}
      >
        {props.page.type === AlbumPageType.FrontCover && (
          <AlbumFrontCover album={props.album} imageSize={ImageSize.Small} />
        )}

        {props.page.type === AlbumPageType.Picture
              && props.page?.images?.map((image) => (
                <AlbumPicture
                  key={image.external_id}
                  albumId={props.album.id}
                  albumPageId={props.page.id}
                  image={image}
                  isControl={props.isControl}
                  pageWidth={props.page.width || 0}
                  pageHeight={props.page.height || 0}
                  imageSize={ImageSize.Medium}
                  isWide={props.isWide}
                  isSelected={props.selectedImageIds?.includes(image.id)}
                  isLazyLoading={isLazyLoading}
                />
              ))}

        {props.page.type === AlbumPageType.BackCover && (
          <AlbumBackCover album={props.album} />
        )}
      </PageLayout>

      {props.isSortable && isSpreadSortable && !props.isSpread && (
        <PageItemSort
          albumPage={props.page}
          spreadIndex={props.spreadIndex}
          isUp={props.isLeft}
          isDown={props.isRight}
          isUpDisabled={!isUpSortable}
          isDownDisabled={!isDownSortable}
          className="mt-4 mb:mt-6"
        />
      )}
    </div>
  )
})
