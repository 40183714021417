import {
  FC, useMemo,
} from 'react'
import {
  Album, CoverColor, CoverType, ImageSize,
} from '@api/gql/graphql'
import {
  coverTypes,
  defaultCoverType,
  parseCoverSizes,
} from '@constants/album'
import { ModalType } from '@modals/_types'
import { useModal } from '@modals/core/use-modal'
import { clsx } from 'clsx'

import { getCoverTypeClass } from '../constants'

import { AlbumCoverImage } from './album-cover-image'

type Props = {
  album: Album
  imageSize?: ImageSize
}

export const AlbumFrontCover: FC<Props> = (props) => {
  const { openModal } = useModal()

  const coverType = useMemo(() => {
    if (!props.album.cover_type) {
      return defaultCoverType
    }

    if (coverTypes.includes(props.album.cover_type)) {
      return props.album.cover_type
    }

    return defaultCoverType
  }, [props.album.cover_type])

  const images = useMemo(() => {
    if (!props.album.pages?.length) {
      return []
    }

    const { count } = parseCoverSizes(coverType)

    return (props.album.pages[0].images?.filter((image) => !image.deleted_at) ?? []).slice(0, count)
  }, [props.album, coverType])

  const onOpenSelectAlbumCover = () => {
    openModal({
      type: ModalType.SELECT_ALBUM_COVER_MODAL,
      album_id: props.album.id,
    })
  }

  const textColors = useMemo(() => ({
    title: props.album.cover_color === CoverColor.White ? 'text-dark-gray' : 'text-white',
    subtitle: props.album.cover_color === CoverColor.White ? 'text-gray-middle' : 'text-white',
    footer: props.album.cover_color === CoverColor.White ? 'text-gray-dark' : 'text-white',
  }), [props.album.cover_color])

  const titleClassName = useMemo(
    () => (props.album.cover_type === CoverType.Cover_0x0
      ? 'text-[8em] md:text-[4.4em] font-medium mt-[8%] ml-[6.8%]' : 'text-[8em] md:text-[3.3em] font-medium'),
    [props.album.cover_type],
  )

  const subtitleClassName = useMemo(
    () => (props.album.cover_type === CoverType.Cover_0x0
      ? 'text-[8em] md:text-[4.4em] font-medium ml-[6.8%]' : 'text-[8em] md:text-[3.3em] font-medium'),
    [props.album.cover_type],
  )

  const footerClassName = useMemo(
    () => (props.album.cover_type === CoverType.Cover_0x0
      ? 'ml-[6.8%] mb-[5.9%]' : ''),
    [props.album.cover_type],
  )

  return (
    <div
      className={clsx(
        'flex h-full flex-col justify-between cursor-pointer font-album',
      )}
      onClick={onOpenSelectAlbumCover}
    >
      <div className="mb-3 md:mb-6">
        <div
          className={clsx(
            'leading-none tracking-tight2 mb-[1%]',
            textColors.title,
            titleClassName,
          )}
        >
          {props.album.title}
        </div>

        <div
          className={clsx(
            'leading-none tracking-tight2',
            textColors.subtitle,
            subtitleClassName,
          )}
        >
          {props.album.subtitle}
        </div>
      </div>

      <div>
        <div
          key={props.album.cover_type}
          className={clsx(
            'grid gap-1 mb-[5%]',
            getCoverTypeClass(coverType),
          )}
        >
          {images.map((image) => (
            <AlbumCoverImage
              key={image.id}
              image={image}
              coverFilter={props.album.cover_filter}
              imageSize={props.imageSize}
            />
          ))}
        </div>

        <div
          className={clsx(
            'flex items-end justify-between text-[2.8em] md:text-[1.3em] leading-none',
            textColors.footer,
            footerClassName,
          )}
        >
          <div>{props.album.creator_name}</div>
          {!!props.album.part && (
            <div>
              {`Part ${props.album.part}`}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
