import { useMemo } from 'react'
import { AlbumImage, AlbumPage, AlbumPageType } from '@api/gql/graphql'
import { albumPagingOffset } from '@constants/album'
import { AlbumPagePagination } from '@share/album/types'
import chunk from 'lodash/chunk'

export const VISIBLE_ALBUM_PAGE_TYPES = [
  AlbumPageType.Text,
  AlbumPageType.Picture,
  AlbumPageType.FrontCover,
]

// Активные страницы.
// Все что фильтрует должно, находится здесь потому что они используются в других местах
export const getLivePages = (pages: AlbumPage[]) => pages
  .map((page) => ({
    ...page,
    images: page.images?.filter((image) => !image.deleted_at) ?? [],
  }))
  .filter((page) => {
    return !!page.images?.length
  })

export const getDeletedPages = (pages: AlbumPage[]) => pages
  .map((page) => ({
    ...page,
    images: page.images?.filter((image) => !!image.deleted_at) ?? [],
  }))
  .filter((page) => {
    return !!page.images?.length
  })

// Активные изображения
// Все что фильтрует должно, находится здесь потому что они используются в других местах
export const getLiveImages = <T>(pages: (AlbumPage & T)[]) => pages
  .filter((page) => [
    AlbumPageType.Picture,
    AlbumPageType.Text,
  ].includes(page.type as AlbumPageType))
  .reduce(
    (items, page) => {
      const images = page.images ? page.images as (AlbumImage & T)[] : []
      return [...items, ...images]
    },
    [] as (AlbumImage & T)[],
  )

const applyPageNumber = (pages: AlbumPage[]) => {
  return pages
    .sort((a, b) => (a.sort ?? 0) - (b.sort ?? 0))
    .map((page, index) => ({
      ...page,
      page_number: index - albumPagingOffset,
    }))
    .map((page) => ({
      ...page,
      images: page.images?.map((image) => ({
        ...image,
        page_number: page.page_number,
      })) ?? [],
    }))
}

export const usePages = (pages: AlbumPage[]) => {
  // Активные страницы
  const pagesLive = useMemo(
    () => applyPageNumber(getLivePages(pages)),
    [pages],
  )

  // Удаленные страницы
  const pagesDeleted = useMemo(
    () => applyPageNumber(getDeletedPages(pages)),
    [pages],
  )

  // Активные изображения
  const imagesLive = useMemo(
    () => getLiveImages<AlbumPagePagination>(pagesLive),
    [pagesLive],
  )

  // Удаленные изображения
  const imagesDeleted = useMemo(
    () => getLiveImages<AlbumPagePagination>(pagesDeleted),
    [pagesDeleted],
  )

  // Развороты
  const spreads = useMemo(() => chunk(pagesLive, 2).map((p) => {
    return p.filter((page) => VISIBLE_ALBUM_PAGE_TYPES.includes(page.type as AlbumPageType))
  }), [pagesLive])

  return {
    pagesLive,
    pagesDeleted,
    imagesLive,
    imagesDeleted,
    spreads,
  }
}
