import { graphql } from '@api/gql/gql'

export const deleteAlbumImages = graphql(`
    mutation deleteAlbumImages($albumImageIds: [ID!]!) {
        delete_album_images(album_image_ids: $albumImageIds) {
            id
            sort
            width
            height
            type
            images {
                id
                album_page_id
                external_id
                image_small_url
                image_medium_url
                image_large_url
                width
                height
                position_x
                position_y
                sort
                duplicate_group
                updated_at
                created_at
                deleted_at
            }
        }
    }
`)
