import { createContext } from 'react'
import { PictureControls, WindowSizes } from '@share/album/types'

export const WindowSizesContext = createContext<WindowSizes>({
  inner: { height: 0, width: 0 },
  outer: { height: 0, width: 0 },
  innerHalf: { height: 0, width: 0 },
  outerHalf: { height: 0, width: 0 },
  padding: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
})

export const PictureControlContext = createContext<PictureControls>({
  delete: false,
  move: false,
})
