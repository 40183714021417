import {
  CSSProperties, FC, useContext, useMemo,
} from 'react'
import { AlbumImage, ImageSize } from '@api/gql/graphql'
import { Image } from '@components/image'
import { clsx } from 'clsx'

import { WindowSizesContext } from '../context'

import { AlbumPictureControl } from './album-picture-control'

type Props = {
  albumId: string
  albumPageId: string
  image: AlbumImage;
  pageWidth: number;
  pageHeight: number;
  imageSize: ImageSize;
  debug?: boolean;
  isControl?: boolean;
  isWide?: boolean
  isSelected?: boolean
  isLazyLoading?: boolean
};

export const AlbumPicture: FC<Props> = (props) => {
  const windowSizes = useContext(WindowSizesContext)

  const inner = props.isWide ? windowSizes.inner : windowSizes.innerHalf

  const getPercent = () => {
    if (props.pageWidth > props.pageHeight) {
      return (inner.width / props.pageWidth) * 100
    }

    return (inner.height / props.pageHeight) * 100
  }

  const resize = (num: number): number => {
    const PERCENT = 100 - getPercent()
    return Math.floor(num * (1 - PERCENT / 100))
  }

  const getOffsetPositionY = () => {
    if (props.pageWidth > props.pageHeight) {
      const height = resize(props.pageHeight || 0)
      return (inner.height - height) / 2
    }

    return 0
  }

  const getOffsetPositionX = () => {
    if (props.pageWidth < props.pageHeight) {
      const width = resize(props.pageWidth || 0)
      return (inner.width - width) / 2
    }

    return 0
  }

  const imageUrl = useMemo(() => {
    switch (props.imageSize) {
      case ImageSize.Small:
        return props.image.image_small_url
      case ImageSize.Large:
        return props.image.image_large_url
      case ImageSize.Medium:
        return props.image.image_medium_url
      default:
        return props.image.image_medium_url
    }
  }, [props.image])

  const imgStyle = useMemo((): CSSProperties => ({
    width: resize(props.image.width || 0),
    height: resize(props.image.height || 0),
    top: resize(props.image.position_y || 0) + getOffsetPositionY(),
    left: resize(props.image.position_x || 0) + getOffsetPositionX(),
  }), [props.image, windowSizes, props.pageWidth, props.pageHeight])

  return (
    <div
      key={props.image.external_id}
      data-id={props.image.id}
      data-external-id={props.image.external_id}
      data-sort={props.image.sort}
      className={clsx(
        'absolute border-[1px] md:border-[2px] box-border overflow-hidden',
        props.isSelected && 'opacity-40',
        'border border-transparent hover:border-primary md:hover:border-transparent transition-colors cursor-pointer md:cursor-auto group',
      )}
      style={imgStyle}
    >
      <Image
        src={imageUrl ?? ''}
        alt=""
        className="object-cover"
        style={imgStyle}
        debug={props.debug}
        loading={props.isLazyLoading ? 'lazy' : undefined}
      />

      {props.isControl && (
        <AlbumPictureControl
          albumId={props.albumId}
          albumPageId={props.albumPageId}
          image={props.image}
        />
      )}
    </div>
  )
}
