import { FC, memo, useMemo } from 'react'
import { AlbumImage, CoverFilter, ImageSize } from '@api/gql/graphql'
import { clsx } from 'clsx'

type Props = {
  image: AlbumImage;
  coverFilter?: CoverFilter | null;
  imageSize?: ImageSize;
};

export const AlbumCoverImage: FC<Props> = memo((props: Props) => {
  const imageUrl = useMemo(() => {
    switch (props.imageSize) {
      case ImageSize.Small:
        return props.image.image_small_url
      case ImageSize.Large:
        return props.image.image_large_url
      case ImageSize.Medium:
        return props.image.image_medium_url
      default:
        return props.image.image_small_url
    }
  }, [props.image])

  return (
    <div className={clsx(
      'relative picture-filter',
      props.coverFilter === CoverFilter.None && '',
      props.coverFilter === CoverFilter.Violet && 'after:bg-filter-violet',
      props.coverFilter === CoverFilter.Gray && 'after:bg-filter-gray',
      props.coverFilter === CoverFilter.Pink && 'after:bg-filter-pink',
    )}
    >
      <img
        className="w-full object-cover object-center aspect-square"
        src={imageUrl ?? ''}
        alt=""
        data-id={props.image.id}
        data-external-id={props.image.external_id}
        data-sort={props.image.sort}
      />
    </div>
  )
})
