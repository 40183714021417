import { CoverType } from '@api/gql/graphql'

export const getCoverTypeClass = (coverType: CoverType): string => {
  switch (coverType) {
    case CoverType.Cover_3x1:
      return 'grid-cols-3'
    case CoverType.Cover_3x2:
      return 'grid-cols-3'
    case CoverType.Cover_4x1:
      return 'grid-cols-4'
    case CoverType.Cover_4x2:
      return 'grid-cols-4'
    default:
      return ''
  }
}

export const getCoverItemClass = (coverType: CoverType): string => {
  switch (coverType) {
    default:
      return 'w-full aspect-square'
  }
}

export const getPageTag = (pageId: string) => `page_${pageId}`
