import { FC } from 'react'
import logoBookColor from '@assets/logo-book-color.svg'
import logoBookWhite from '@assets/logo-book-white.svg'

type Props = {
  type: 'white' | 'color'
  className?: string
}

export const Logo: FC<Props> = (props) => {
  if (props.type === 'white') {
    return <img src={logoBookWhite} alt="" className={props.className} />
  }

  if (props.type === 'color') {
    return <img src={logoBookColor} alt="" className={props.className} />
  }

  return <></>
}
