import { FC } from 'react'
import { Album, CoverColor } from '@api/gql/graphql'
import { clsx } from 'clsx'

import { Logo } from './logo'

type Props = {
  album: Album
}

export const AlbumBackCover: FC<Props> = (props) => {
  const isWhite = props.album.cover_color === CoverColor.White

  return (
    <div className="flex h-full flex-col justify-between">
      <div />
      <div className="flex justify-center">
        <Logo type={isWhite ? 'color' : 'white'} className="w-14" />
      </div>
      <div className={clsx(
        'text-[1.1em] text-center w-full gap-x-1 font-album font-regular  tracking-tight1',
        isWhite ? 'text-gray-dark' : 'text-white',
      )}
      >
        easyphotobook.me
      </div>
    </div>
  )
}
