import { FC, memo, useMemo } from 'react'
import { AlbumPage } from '@api/gql/graphql'
import { useMutation } from '@apollo/client'
import { toast } from '@components/toast'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { amplitude, AmplitudeEvent } from '@helpers/amplitude'
import { Button } from '@nextui-org/button'
import { useIsMobile } from '@nextui-org/use-is-mobile'
import { clsx } from 'clsx'

import { swapAlbumPage } from '../api'

type Props = {
  albumPage: AlbumPage;
  spreadIndex: number;
  className?: string;
  isUp?: boolean;
  isDown?: boolean;
  isUpDisabled?: boolean;
  isDownDisabled?: boolean;
};

export const PageItemSort: FC<Props> = memo((props: Props) => {
  const [swapAlbumPageMutation, swapAlbumPageState] = useMutation(swapAlbumPage, {
    onError: (err) => {
      if (err.graphQLErrors.length) {
        toast.error(err.graphQLErrors[0].message)
      }
    },
  })
  const isMobile = useIsMobile()

  const scrollToSpread = (index: number) => {
    document.getElementById(`spread-${index}`)?.scrollIntoView({ behavior: 'smooth' })
  }

  const onUp = () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumSpreadClickUp,
      spread_index: props.spreadIndex,
    })

    swapAlbumPageMutation({
      variables: { albumPageId: props.albumPage.id, direction: true },
      onCompleted: () => {
        scrollToSpread(props.spreadIndex - 1)
      },
    })
  }

  const onDown = () => {
    amplitude.event({
      type: AmplitudeEvent.AlbumSpreadClickDown,
      spread_index: props.spreadIndex,
    })

    swapAlbumPageMutation({
      variables: { albumPageId: props.albumPage.id, direction: false },
      onCompleted: () => {
        scrollToSpread(props.spreadIndex + 1)
      },
    })
  }

  const iconChevronUp = useMemo(() => <FontAwesomeIcon icon={faChevronUp} className="text-gray-400" />, [])
  const iconChevronDown = useMemo(() => <FontAwesomeIcon icon={faChevronDown} className="text-gray-400" />, [])

  return (
    <div className={clsx('flex', props.className, props.isDown && 'justify-end')}>
      {props.isUp && (
        <Button
          className="col-span-8 text-black bg-gray-very-light"
          variant="flat"
          size={isMobile ? 'sm' : 'lg'}
          isDisabled={swapAlbumPageState.loading || props.isUpDisabled}
          startContent={iconChevronUp}
          onClick={onUp}
        >
          Move spread up
        </Button>
      )}

      {props.isDown && (
        <Button
          className="col-span-8 text-black bg-gray-very-light"
          variant="flat"
          size={isMobile ? 'sm' : 'lg'}
          isDisabled={swapAlbumPageState.loading || props.isDownDisabled}
          startContent={iconChevronDown}
          onClick={onDown}
        >
          Move spread down
        </Button>
      )}
    </div>
  )
})
